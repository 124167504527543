import { FC, useRef, useState } from "react";
import Modal from "@components/Modal";
import { Button } from "@components/button";
import { FILE_EXTENSION_URL } from "constant";
import { convertFileToJson } from "@utils/table-utils";
import { Loader } from "@components/Loader2";

import "./sba-file-upload.scss";
import { useNotification } from "@hooks/notification";

const fileFormat: any = {
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
  xlsx: "xlsx",
  "application/vnd.ms-excel": "xls",
  xls: "xls",
  "application/x-iwork-numbers-sffnumbers" : 'numbers'
 };


export const SbaFileUpload: FC<any> = ({onClose, handleSuccess}) => {
  const fileInputRef = useRef<any>(null)
  const [selectedFile, setSelectedFile] = useState<any>(null)
  const [publishloading, setPublishLoading] = useState(false);
  const {errorNotification} = useNotification()
  const handleOnChange = (event: any) => {
    const file = event.target.files[0]
    if(!fileFormat[file?.type]){
      errorNotification(`Please upload a valid file`);
      fileInputRef.current.value = null;
      return;
    }
    setSelectedFile(file);
  };

  const removeFile = (e: any) => {
    e.preventDefault();
    fileInputRef.current.value = null;
    setSelectedFile(null);
  }

  const handleOnDrop = async (event: any) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    setSelectedFile(files[0])
  };

  const handleUpload = async () => {
    setPublishLoading(true)
    const jsonData = await convertFileToJson(selectedFile);
    handleSuccess(jsonData);
    setPublishLoading(false)
  }

  return (
    <Modal
      title=""
      outerClassName="bisheet-modal"
      visible={true}
      onClose={() => onClose(false)}
    >
      <div className="bisheet-modal_header">
        <h3>Upload bid sheet</h3>
        <p>Upload bid sheet</p>
      </div>
      <div className="bisheet-modal_body">
        <div
          className={`data-room-file-container ${
            selectedFile?.name ? "bg-none" : ""
          }`}
          onDrop={handleOnDrop}
        >
          <div className="data-room-content">
            {selectedFile?.name ? (
              <img
                src={FILE_EXTENSION_URL.xlsx}
                alt={selectedFile?.name}
                className="file-icon"
              />
            ) : (
              <span className="ri ri-file-upload-line"></span>
            )}
            {selectedFile?.name ? (
              <p>{selectedFile?.name}</p>
            ) : (
              <p>Drag and Drop files or <span className="highlight-text">Browse</span></p>
            )}

            {selectedFile?.name ? (
              <Button
                handleClick={removeFile}
                label="Remove"
                type="btn-danger btn-h-auto"
              />
            ) : (
              <small>Supported file format: XSLX</small>
            )}
            <input
              id={"dataroom-file-browse"}
              ref={fileInputRef}
              disabled={selectedFile?.name}
              type="file"
              placeholder="select logo"
              className={"image_browse"}
              accept=".xlsx, .xls, .numbers"
              onChange={handleOnChange}
              multiple={false}
            />
          </div>
        </div>
      </div>
      <div className="bidsheet-modal_footer">
        <Button
          handleClick={() => onClose(false)}
          label="Cancel"
          type="btn-transparent width-fixed btn-h-auto"
        />
        <Button
          handleClick={handleUpload}
          disabled={!selectedFile?.name}
          label={
            publishloading ? (
              <Loader className="loader-white" dimension={16} />
            ) : (
              "Upload"
            )
          }
          type="width-fixed btn-h-auto"
        />
      </div>
    </Modal>
  );
};