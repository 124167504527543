import { useEffect } from "react";
import { UAParser } from "ua-parser-js";

export const MpcMobileRedirect = () => {

    const parser = new UAParser(window.navigator.userAgent);
    const result = parser.getResult();
    const deviceOS = result?.os?.name;

    useEffect(() => {
        if(deviceOS === "Android"){
            window.location.href = "https://play.google.com/store/apps/details?id=com.liquidity";
        }
        else{
            window.location.href = "https://apps.apple.com/in/app/testflight/id899247664";
        }
    },[]);

    return (
        <div></div>
    );
};
export default MpcMobileRedirect