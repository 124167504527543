import React, { useState, useEffect } from 'react';
import './counter.scss';

const FlipCountdown = ({seconds,setSeconds}:any) => {
  const [isSliding, setIsSliding] = useState<boolean>(false);

  useEffect(() => {
    if (seconds === 0) return; 

    const timer = setInterval(() => {
      setIsSliding(true); // Trigger slide animation
      setTimeout(() => {
        setSeconds((prevSeconds:any) => (prevSeconds > 0 ? prevSeconds - 1 : prevSeconds)); // Decrement seconds after animation
        setIsSliding(false); // Reset slide state
      }, 500); // Sync with the slide animation duration (0.5s)
    }, 1000); // Trigger every second

    return () => clearInterval(timer);
  }, [seconds]);

  // Get tens and units digits for proper sliding behavior
  const getTensDigit = (num: number): number => Math.floor(num / 10);
  const getUnitsDigit = (num: number): number => num % 10;

  const currentTens = getTensDigit(seconds);
  const currentUnits = getUnitsDigit(seconds);
  const nextTens = getTensDigit(seconds - 1);
  const nextUnits = getUnitsDigit(seconds - 1);

  return (
    <div className="slide-wrapper">
      <div className="digit-container">
        <div className={`digit ${isSliding && currentUnits === 0 ? 'sliding' : ''}`}>
          <div className="current">{currentTens}</div>
          <div className="next">{nextTens}</div>
        </div>
        <div className={`digit ${isSliding ? 'sliding' : ''}`}>
          <div className="current">{currentUnits}</div>
          <div className="next">{nextUnits}</div>
        </div>
      </div>

      <div className="label">Seconds</div>
    </div>
  );
};

export default FlipCountdown;
