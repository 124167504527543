export const ITEM = [
  {
    title: "Profile",
    icon: "user",
    content: "Important account details",
    url: "/profile",
  },

  // "TODO: We hiude the code of Auction"
  // {
  //   title: "User Bidding",
  //   icon: "auction",
  //   viewBox: "0 0 24 24",
  //   content: "",
  //   url: "/user-bid-list",
  // },
  {
    title: "Co-owner/Authorised User",
    icon: "parent",
    viewBox: "0 0 24 24",
    content: "",
    url: "/profile/authorise-users",
  },
  // {
  //   title: "My Wallet",
  //   icon: "wallet",
  //   content: "",
  //   url: "/profile/wallet",
  // },
  {
    title: "Dark mode",
    icon: "theme-dark",
    content: "Switch Dark/Light Mode",
  },
  {
    title: "Admin",
    icon: "admin",
    viewBox: "0 0 24 24",
    url: "/admin/minted/assets",
  },
  {
    title: "Log out",
    icon: "exit",
  },
];

export const COOWNERS_ITEM = [
  // {
  //   title: "My Wallet",
  //   icon: "wallet",
  //   content: "",
  //   viewBox: "0 0 24 24",
  //   url: "/profile/wallet",
  // },
  {
    title: "Dark mode",
    icon: "theme-dark",
    content: "Switch Dark/Light Mode",
  },
  {
    title: "Log out",
    icon: "exit",
  },
];
