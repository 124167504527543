// @ts-ignore
import {generate,sign} from '@satschel/mpc-crypto-wallet';
import { useCallback, useState } from "react";
import { firestoreDb } from "libs";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { Json } from "../../../types/common";

const useMpcWalletApi = () => {
  const [signHash, setSignHash] = useState<any>([]);
  const blockchainShard = async (number?: string) => {
      const keys = await generate();
    const shards = [keys.shards[0], keys.shards[1], keys.shards[2]];
    if (number) {
      await setDoc(doc(firestoreDb, "shards", number), {
        createdAt: new Date().toISOString(),
        shards1: shards[0],
        shards2: shards[1],
        shards3: shards[2],
      });
    }
    const data = "trade data";
    const signHash = await sign(data, shards);
      setSignHash([...shards]);
      window.ReactNativeWebView &&
      window.ReactNativeWebView.postMessage(JSON.stringify({ data: keys }));
    
    return signHash;
  };

  const getFirebaseShards = useCallback(async (number: any) => {
    try {
      if (number) {
        const docRef = doc(firestoreDb, "shards", number);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          return docSnap.data();
        } else {
          console.log("No such document!");
        }
      }
    } catch (error: any) {
      console.error(error?.message);
    }
  }, []);

  const getSignHash=useCallback(async (shardskey:any,data:Json)=>{
    try {
      const signHash = await sign(data, shardskey);
      return signHash;
    } catch (error) {
      console.log("error",error)
    }
  },[])

  return { blockchainShard, signHash, getFirebaseShards ,getSignHash};
};

export default useMpcWalletApi