import React, { useMemo } from "react";
import { Image } from "@storybook";
import { Json } from ".././../../../types/common";
import { useCurrency } from "@hooks/currency";

import "./buysellNotification.scss";

const BuysellNotification = (data: Json) => {
  const { formatCurrencyWithBillion } = useCurrency();
  const notificationData = useMemo(() => {
    return {
      title: `Order ${data?.data?.type === "ORDER_CREATION" ? "placed" : ""}`,
      status: data?.data?.status,
      time: data?.data?.createdAt
        ? `${new Date(data?.data?.createdAt).getDate()} ${new Date(
            data?.data?.createdAt
          ).toLocaleDateString("en-US", {
            month: "short",
          })}, ${new Date(data?.data?.createdAt).toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })}`
        : "-",
      userName: data?.data?.metadata?.orderPlacedBy,
      readStatus: data?.data?.readStatus,
      mode: data?.data?.mode,
      qty: data?.data?.metadata?.quantity,
      symbol: data?.data?.metadata?.symbol,
      avgPrice: data?.data?.metadata?.averagePrice,
      assetType: data?.data?.metadata?.assetType,
      action: data?.data?.metadata?.orderType,
      userAvatar: data?.data?.metadata?.profileUrl,
      bank: data?.data?.metadata?.bank,
      amount: data?.data?.metadata?.totalAmount,
    };
  }, [
    data?.data?.createdAt,
    data?.data?.metadata?.assetType,
    data?.data?.metadata?.averagePrice,
    data?.data?.metadata?.bank,
    data?.data?.metadata?.orderPlacedBy,
    data?.data?.metadata?.orderType,
    data?.data?.metadata?.profileUrl,
    data?.data?.metadata?.quantity,
    data?.data?.metadata?.symbol,
    data?.data?.metadata?.totalAmount,
    data?.data?.mode,
    data?.data?.readStatus,
    data?.data?.status,
    data?.data?.type,
  ]);

  const statusColor: any = {
    Processing: "processing",
    Cancelled: "cancelled",
    Success: "completed",
    Failure: "failure",
  };

  return (
    <div className="notificationSection">
      <div className="buysellNotification">
        <div className="buysellNotification__left">
          <div className="buysellNotification__left__icon">
            <i
              className={`ri-money-dollar-circle-fill ${
                notificationData?.mode === "buy" ? "buy" : "sell"
              }`}
            />
          </div>
          {notificationData?.readStatus === "UNREAD" && (
            <div className="buysellNotification__left__readMark"></div>
          )}
          <div>
            <div className="buysellNotification__left__title">
              {notificationData?.symbol}
            </div>
            <div className="buysellNotification__right__orderDetails">
              <span className="buysellNotification__right__orderDetails__qty">
                <div>QTY</div>
                <span> {notificationData?.qty}</span>
              </span>
              <span className="buysellNotification__right__orderDetails__bullet"></span>
              <span className="buysellNotification__right__orderDetails__price">
                <div>Avg. price</div>
                <span>
                  {" "}
                  {formatCurrencyWithBillion(notificationData?.avgPrice,2)}
                </span>
              </span>
            </div>
            <div className="buysellNotification__left__userDetail">
              {notificationData?.userAvatar ? (
                <img
                  className="buysellNotification__left__userDetail__userImg"
                  src={notificationData?.userAvatar}
                  alt="profile_image"
                />
              ) : (
                <Image
                  className="buysellNotification__left__userDetail__userImg"
                  fileName={"icon/avatar.svg"}
                />
              )}
              <div className="buysellNotification__left__userDetail__userName">
                {notificationData?.userName}
              </div>
            </div>
          </div>
        </div>
        <div className="buysellNotification__right">
          <div className="buysellNotification__right__amount">
            {formatCurrencyWithBillion(notificationData?.amount, 2)}
          </div>
          <div className="buysellNotification__right__status">
            <div
              className={`buysellNotification__right__dot  ${
                statusColor[notificationData?.status]
              }`}
            ></div>
            <div> {notificationData?.status}</div>
          </div>
          <div className="buysellNotification__right__orderDetails__orderType">
            <div> {notificationData?.assetType}</div>
            <span
              className={`${
                notificationData?.action === "Buy"
                  ? "buysellNotification__right__orderDetails__orderType__buy"
                  : "buysellNotification__right__orderDetails__orderType__sell"
              }`}
            >
              {notificationData?.action}
            </span>
          </div>
        </div>
      </div>
      <div className="buysellNotification__right__time">
        {" "}
        {notificationData?.time}
      </div>
    </div>
  );
};

export default BuysellNotification;
