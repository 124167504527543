import { getApp, getApps, initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getMessaging } from "firebase/messaging";
import { REACT_APP_FIREBASE as FIREBASE } from "envs";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = JSON.parse(FIREBASE);

const app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApp();
const db = getDatabase(app);
const firestoreDb = getFirestore(app);
let messaging: any = null;

if (typeof window !== "undefined" && "serviceWorker" in navigator) {
  messaging = getMessaging(app);
}

export { messaging, firestoreDb, db };

