import React, { useCallback, useMemo, useState, useEffect } from "react";

import "./place-bid-modal.scss";
import { Json } from "../../../../types";
import NewModal from "../../../../components/new-modal/new-modal";
import { Button, CountdownTimer, Input, Loader } from "../../../../components";
import {
  useAuctionApis,
  useCurrency,
  useNetwork,
  useNotification,
  usePrevious,
} from "../../../../hooks";
import {
  AuctionBidPostState,
  AuctionListState,
  userSelectedCurrencyState,
} from "../../../../states";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { addMinutes } from "date-fns";
import { APIS } from "../../../../constant";
import useAuctionHook from "@views/AuctionConsumer/hook";

type IPlaceBidModal = {
  open: boolean;
  onClose: () => void;
  details: Json;
};

export const PlaceBidModal = ({
  open,
  onClose,
  details: detailProp,
}: IPlaceBidModal) => {
  const { formatCurrencyWithBillion, bondPointToAmount } = useCurrency();
  const { errorNotification, successNotification } = useNotification();
  const {
    remove: removeUserMaxBid,
    data: removeMaxBidData,
    loading: isRemovingMaxBid,
  } = useNetwork();
  const prevIsRemovingMaxBid = usePrevious(isRemovingMaxBid);
  const currency = useRecoilValue(userSelectedCurrencyState);
  const { postAuctionBid } = useAuctionApis();
  const { loading } = useRecoilValue(AuctionBidPostState);
  const setRecoilAuctionList = useSetRecoilState(AuctionListState);
  const [details, setDetails] = useState(detailProp || {});
  const [stepMultiplier, setStepMultiplier] = useState(
    !!detailProp?.totalBid ? 1 : 0
  );
  const [maxBidPrice, setMaxBidPrice] = useState("");
  const { calculateWeightedYieldPer, sbaAndNormalPrice, isSbaLoan } =
    useAuctionHook(details);

  const yourBidPrice = useMemo(() => {
    if (details?.tradeType === "classic") {
      return (
        parseFloat(details?.currentBidPrice) +
        parseFloat(details?.stepPrice) * stepMultiplier
      );
    } else {
      return parseFloat(details?.currentBidPrice);
    }
  }, [stepMultiplier, details, currency]);

  const handlePlaceBid = useCallback(
    (type: string) => {
      const payload: Json = {
        assetId: details?.assetId,
        auctionId: details?.id,
        isBuyNowPrice: false,
      };
      if (type === "maxBid" && maxBidPrice)
        payload.maxBidPrice = isSbaLoan
          ? bondPointToAmount(details?.currentLoanBalance, maxBidPrice)
          : parseFloat(maxBidPrice);
      else {
        setMaxBidPrice("");
        payload.bidPrice = yourBidPrice;
      }

      postAuctionBid(payload);
    },
    [details, yourBidPrice, maxBidPrice, isSbaLoan]
  );

  const handleRemoveUserMaxBidPrice = useCallback(() => {
    removeUserMaxBid(
      `${APIS.AuctionMaxBid}/${details?.id}`,
      {},
      { apiResponse: true }
    );
  }, [details]);

  useEffect(() => {
    if (
      removeMaxBidData?.message !== "ok" &&
      !isRemovingMaxBid &&
      prevIsRemovingMaxBid
    ) {
      errorNotification(removeMaxBidData?.message);
    }

    if (
      removeMaxBidData?.message === "ok" &&
      !isRemovingMaxBid &&
      prevIsRemovingMaxBid
    ) {
      setRecoilAuctionList((prev) => {
        let temp = JSON.parse(JSON.stringify(prev?.data?.data));
        const idx = temp.findIndex((_data: Json) => _data?.id === details?.id);
        temp[idx] = { ...temp[idx], userMaxBidPrice: 0 };
        return { ...prev, data: { ...prev.data, data: [...temp] } };
      });
      setDetails((prev) => ({ ...prev, userMaxBidPrice: 0 }));
      successNotification("User max bid successfully removed");
    }
  }, [removeMaxBidData]);

  const handleChangeUserInput = useCallback(
    (e: any) => {
      const validPrice = /^(?!0{2,})(?!\.$)[0-9]{0,10}(?:[.][0-9]{0,4})?$/.test(
        e.target.value
      );

      if (e.target.value >= 0 && validPrice) setMaxBidPrice(e.target.value);
    },
    [stepMultiplier]
  );

  const renderMaxBidInfo = useMemo(() => {
    const content =
      details?.tradeType === "classic"
        ? "Once you submit your maximum bid, our automated system starts placing bids on your behalf. It will start with the current bid price and incrementally increase the bid until it reaches your maximum bid amount."
        : "Once you submit your maximum budget, if your maximum budget is equal to or lower than the maximum budget set for the auction, you will be considered the winning bidder.";
    return (
      <div className="info-icon">
        <i className="ri-information-fill" data-tooltip-id={details.id} />
        <ReactTooltip
          id={details.id}
          place={details?.tradeType === "classic" ? "top" : "top-start"}
          content={content}
        />
      </div>
    );
  }, [details]);

  const renderDate = useCallback(
    ({ days, hours, minutes, seconds, completed }: Json, type?: string) => {
      if (
        type === "dutchNextStep" &&
        completed &&
        details?.currentBidPrice > details?.reservePrice
      ) {
        setDetails((prev) => {
          const {
            currentBidPrice,
            stepPrice,
            maxAuctionBidPrice,
            userMaxBidPrice,
            reservePrice,
            timeStepMinutes,
            timeStepHours,
            dutchPriceUpdateTime,
          } = prev || {};
          const price = Math.max(
            currentBidPrice - stepPrice,
            maxAuctionBidPrice,
            reservePrice
          );

          if (price === maxAuctionBidPrice) {
            return {
              ...prev,
              currentBidPrice: price,
              userBidPrice: userMaxBidPrice,
              status: "completed",
            };
          }

          const totalMinutes =
            price === reservePrice ? 0 : timeStepHours * 60 + timeStepMinutes;
          return {
            ...prev,
            currentBidPrice: price,
            dutchPriceUpdateTime: addMinutes(
              new Date(dutchPriceUpdateTime),
              totalMinutes
            ),
          };
        });
      }

      if (type === "endTimer" && completed) {
        setDetails((prev) => ({ ...prev, status: "completed" }));
      }

      return `${!!days ? days + "D " : ""}${(hours < 10 ? "0" : "") + hours}:${
        (minutes < 10 ? "0" : "") + minutes
      }:${(seconds < 10 ? "0" : "") + seconds}`;
    },
    [details]
  );

  const showMaxBidInput = useMemo(() => {
    if (details?.tradeType === "classic") {
      return (
        !details?.userMaxBidPrice ||
        details?.currentBidPrice > details?.userMaxBidPrice
      );
    } else {
      return !details?.userMaxBidPrice;
    }
  }, [details]);

  const renderInputDesc = useMemo(() => {
    if (isSbaLoan) {
      const amount =
        bondPointToAmount(details?.currentLoanBalance, maxBidPrice) || 0;
      const avgYieldPer = calculateWeightedYieldPer(details, amount, 6);
      return (
        <>
          Equivalent value : {formatCurrencyWithBillion(amount, 2)}
          <br />
          Equivalent weighted avg. yield : {avgYieldPer}%
        </>
      );
    }
    return "";
  }, [details, currency, maxBidPrice]);

  const renderWeightedYieldPer = useCallback(
    (value: string | number) => {
      const amount: any = value || 0;
      const avgYieldPer = calculateWeightedYieldPer(details, amount, 6);
      return `Equivalent weighted avg. yield : ${avgYieldPer}%`;
    },
    [details]
  );

  return (
    <NewModal
      isOpen={open}
      modalName="Place Bid"
      className="new-react-modal place-bid-modal-container"
      closeModal={onClose}
    >
      <div className="bid-card-image">
        <span className="label">{details?.tradeType}</span>
        {!!details?.assetImage?.[0] && (
          <img className="image" src={details?.assetImage?.[0]} alt="asset" />
        )}
        {!details?.assetImage?.[0] && <i className="ri-image-add-line" />}
        <div className="arrow-label">
          {details?.category}
          <img
            className="arrow-label-img"
            src="/media/icon/ArrowTail.svg"
            alt="Assets"
          />
        </div>
      </div>

      <div
        className={`stats-details ${
          details?.tradeType === "dutch" ? "rm-border" : ""
        }`}
      >
        <div className="stats-item">
          <p className="stat-label">Current bid price</p>
          <p className="stat-value">
            {sbaAndNormalPrice(details, {
              price: details?.currentBidPrice,
            })}
          </p>
          {isSbaLoan && (
            <p className="stat-label">
              {formatCurrencyWithBillion(details?.currentBidPrice, 2)}
            </p>
          )}
        </div>
        <div className="stats-item">
          <p className="stat-label">Price Step</p>
          <p className="stat-value">
            {sbaAndNormalPrice(details, {
              price: details?.stepPrice,
            })}
          </p>
          {isSbaLoan && (
            <p className="stat-label">
              {formatCurrencyWithBillion(details?.stepPrice, 2)}
            </p>
          )}
        </div>
        {details?.tradeType === "dutch" && (
          <div className="stats-item">
            <p className="stat-label">Time left to next step</p>
            <p className="stat-value">
              {details?.status === "live" && (
                <CountdownTimer
                  dateTime={details?.dutchPriceUpdateTime}
                  renderer={(time) => renderDate(time, "dutchNextStep")}
                />
              )}
              {details?.status !== "live" && "00:00:00"}
            </p>
          </div>
        )}
        {details?.tradeType === "dutch" && (
          <div className="stats-item">
            <p className="stat-label">Time left for auction</p>
            <p className="stat-value">
              {details?.status === "live" && (
                <CountdownTimer
                  dateTime={details?.endTime}
                  renderer={(time) => renderDate(time, "endTimer")}
                />
              )}

              {details?.status !== "live" && "00:00:00"}
            </p>
          </div>
        )}
        {details?.tradeType === "dutch" && (
          <div className="stats-item">
            <p className="stat-label">Starting bid</p>
            <p className="stat-value">
              {sbaAndNormalPrice(details, {
                price: details?.startPrice,
              })}
            </p>
            {isSbaLoan && (
              <p className="stat-label">
                {formatCurrencyWithBillion(details?.startPrice, 2)}
              </p>
            )}
          </div>
        )}
        {details?.tradeType === "dutch" && (
          <div className="stats-item">
            <p className="stat-label">Reserve price</p>
            <p className="stat-value">
              {sbaAndNormalPrice(details, {
                price: details?.reservePrice,
              })}
            </p>
            {isSbaLoan && (
              <p className="stat-label">
                {formatCurrencyWithBillion(details?.reservePrice, 2)}
              </p>
            )}
          </div>
        )}
        {details?.tradeType === "classic" && (
          <div className="stats-item">
            <p className="stat-label">Your last bid</p>
            <p className="stat-value">
              {sbaAndNormalPrice(details, {
                price: details?.userBidPrice,
              })}
              {!!details?.userBidPrice && (
                <span
                  className={`last-bid-status ${
                    details?.bidStatus === "highest-bid" ? "success" : ""
                  }`}
                >
                  {details?.bidStatus === "highest-bid" ? "Highest" : "Outbid"}
                </span>
              )}
            </p>
            {isSbaLoan && (
              <p className="stat-label">
                {formatCurrencyWithBillion(details?.userBidPrice, 2)}
              </p>
            )}
          </div>
        )}
      </div>

      <div className="user-input-bid-container row-colon">
        {showMaxBidInput && (
          <Input
            suffixLabelIcon={renderMaxBidInfo}
            placeholder={
              details?.tradeType === "classic"
                ? "Enter your max bid"
                : "Enter an amount"
            }
            label={
              details?.tradeType === "classic"
                ? "Choose your maximum bid"
                : "My max budget"
            }
            value={maxBidPrice || ""}
            inputType="number"
            handleChange={handleChangeUserInput}
            suffixText={
              isSbaLoan
                ? parseFloat(maxBidPrice) > 1
                  ? "bond points"
                  : "bond point"
                : currency.code
            }
            isShowButton
            buttonDisabled={
              !maxBidPrice || loading || details?.status !== "live"
            }
            buttonLabel={
              !!maxBidPrice && loading ? (
                <>
                  <Loader className="green-transparent" dimension={15} />{" "}
                  Confirming
                </>
              ) : (
                `Confirm`
              )
            }
            handleClickBtn={() => handlePlaceBid("maxBid")}
            inputPrefixDesc={
              details?.tradeType === "classic" ? (
                <>
                  Enter bid greater than{" "}
                  {sbaAndNormalPrice(details, {
                    price: details?.currentBidPrice + details?.stepPrice,
                  })}
                </>
              ) : (
                ""
              )
            }
            inputDesc={renderInputDesc}
          />
        )}

        {!showMaxBidInput && (
          <div className="max-bid-details">
            <p className="stat-label">
              Your current max.{" "}
              {details?.tradeType === "classic" ? "bid" : "budget"}
            </p>
            <div>
              <p className="stat-value">
                <span>
                  {sbaAndNormalPrice(details, {
                    price: details?.userMaxBidPrice,
                  })}
                </span>
                <Button
                  label={"Remove"}
                  type="button-red-light btn-h-auto"
                  handleClick={handleRemoveUserMaxBidPrice}
                  disabled={isRemovingMaxBid || details?.status !== "live"}
                />
              </p>
              {isSbaLoan && (
                <small>
                  {formatCurrencyWithBillion(details?.userMaxBidPrice, 2)}
                </small>
              )}
            </div>
          </div>
        )}
        <p className="separate-line-with-text mt-20 mb-20">
          <span>OR</span>
        </p>
      </div>

      {details?.tradeType === "classic" && (
        <div className={`stats-details row-colon rm-border`}>
          <p className="stat-label">Current bid price</p>
          <div>
            <p className="stat-value">
              {sbaAndNormalPrice(details, {
                price: details?.currentBidPrice,
              })}
            </p>
            {isSbaLoan && (
              <small>
                {formatCurrencyWithBillion(details?.currentBidPrice, 2)}
              </small>
            )}
          </div>
          <p className="stat-label">Price Step</p>
          <div>
            <p className="stat-value">
              {sbaAndNormalPrice(details, {
                price: details?.stepPrice,
              })}
            </p>
            {isSbaLoan && (
              <small>{formatCurrencyWithBillion(details?.stepPrice, 2)}</small>
            )}
          </div>
          <p className="stat-label">Price step multiplier</p>
          <div className="bid-multiplier-container">
            <span
              className={`bid-plus-minus`}
              onClick={() =>
                setStepMultiplier((prev) =>
                  prev - 1 <= 0 ? (!!details?.totalBid ? 1 : 0) : prev - 1
                )
              }
            >
              -
            </span>
            <span className="bid-plus-minus bid-value">{stepMultiplier}</span>
            <span
              className={`bid-plus-minus`}
              onClick={() => setStepMultiplier((prev) => prev + 1)}
            >
              +
            </span>
          </div>
        </div>
      )}

      {details?.tradeType === "classic" && (
        <div className="text-center user-input-bid-container mb-10">
          <p className="stat-value fs-24">
            {sbaAndNormalPrice(details, {
              price: yourBidPrice,
              labelClass: "stat-label",
            })}
          </p>
          {isSbaLoan && (
            <p className="fw-600">
              {formatCurrencyWithBillion(yourBidPrice, 2)}
            </p>
          )}
          {isSbaLoan && <p>{renderWeightedYieldPer(yourBidPrice)}</p>}
          <p className="stat-label">Your bidding price</p>
          {!showMaxBidInput && (
            <div className="max-bid-text">
              Bidding price must be greater than{" "}
              {sbaAndNormalPrice(details, { price: details?.userMaxBidPrice })}{" "}
              to place a bid
            </div>
          )}
        </div>
      )}

      {details?.tradeType === "dutch" && (
        <div className="text-center user-input-bid-container mb-10">
          <p className="stat-value fs-24">
            {sbaAndNormalPrice(details, {
              price: details?.currentBidPrice,
            })}
          </p>
          {isSbaLoan && (
            <p className="fw-600">
              {formatCurrencyWithBillion(details?.currentBidPrice, 2)}
            </p>
          )}
          {isSbaLoan && (
            <p>{renderWeightedYieldPer(details?.currentBidPrice)}</p>
          )}
          <p className="stat-label">Current bid price</p>
        </div>
      )}

      <div className="text-center">
        <Button
          label={
            !maxBidPrice && loading ? (
              <>
                <Loader className="white-transparent" dimension={15} /> Placing
                bid
              </>
            ) : (
              `Place bid`
            )
          }
          type="button-green buy-btn"
          handleClick={() => handlePlaceBid("bid")}
          disabled={
            details?.status !== "live" ||
            loading ||
            (details?.tradeType === "classic" &&
              details?.userMaxBidPrice >= yourBidPrice)
          }
        />
      </div>
    </NewModal>
  );
};
