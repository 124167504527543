import { Json } from "../../../../types/common";
import styles from "./loan-table.module.sass";
import cn from "classnames";
import { useState } from "react";


interface ILoanTable {
    value: string;
    setValue: any;
    options: string[] | number[] | Json[];
    searchValue: string;
    setSearchValue: any;
    offsetValue: number;
    setOffsetValue: any;
    lastOffset: number;
  }
  
  export const LoanTable = ({value, setValue, options, searchValue, setSearchValue, offsetValue, setOffsetValue, lastOffset}: ILoanTable)=> {
      const [pageValue, setPageValue] = useState(offsetValue + 1);
      
    const handleInputChange = (e: any) => {
        const inputValue = e.target.value;
        if(inputValue <= lastOffset + 1){
            const parsedValue = inputValue && parseInt(inputValue, 10) > 0 ? parseInt(inputValue, 10) - 1 : 0;
            setOffsetValue(parsedValue);
            setPageValue(inputValue);
        }
    };

    const handleDoubleLeftClick = () =>{
        setOffsetValue(0);
        setPageValue(1);
    };

    const handleLeftClick = () =>{
        setOffsetValue((prev: number) => {
            const newOffset = prev - 1;
            setPageValue(newOffset + 1);
            return newOffset;
        });
    };

    const handleRightClick = () =>{
        setOffsetValue((prev: number) => {
            const newOffset = prev + 1;
            setPageValue(newOffset + 1);
            return newOffset;
        });
    };

    const handleDoubleRightClick = () =>{
        setOffsetValue(lastOffset);
        setPageValue(lastOffset + 1);
    };

    return(
        <div className={styles.loanTable}>
            <div className={styles.head}>
                <div className={styles.orderBook}>Loans</div>
            </div>
            <div className={styles.searchField}>
                <i className={`ri-search-line ${styles.searchIcon}`}></i>
                <input type="text" className={styles.searchInput} placeholder="Search"
                onChange={(e)=> setSearchValue(e.target.value)}
                value={searchValue}
                />
            </div>
            <div className={styles.loanDataList}>
                {!!options?.length ? options?.map((item: any)=>{
                    return <div className={cn(
                        styles.loanData,
                        { [styles.selectedLoan]: item?.symbol === value },
                        {[styles.activeSbaOrderBook]: item?.symbol === value}
                      )} onClick={()=> setValue(item?.symbol)} key={item?.symbol}>
                        <div className={styles.symbol}>{item?.symbol}</div>
                        <div className={styles.loanValue}>Loan # : {item?.lender_loan_number}</div>
                    </div>
                })
                :
                <div className={styles.emptyData}>No data found!</div>
                }
            </div>
            <div className={styles.pagination}>
                <span className={styles.pagination_line}>Page {offsetValue + 1} of {lastOffset +1}</span>
                <span className={styles.pagination_numbers}>
                    <div className={offsetValue === 0 ? styles.disable: ""}><i className={`ri-arrow-left-double-line `} onClick={handleDoubleLeftClick}></i></div>
                    <div className={offsetValue === 0 ? styles.disable: ""}><i className={`ri-arrow-left-s-line`} onClick={handleLeftClick}></i></div>
                    <input type="number" value={pageValue} onChange={(e)=>handleInputChange(e)}/>
                    <div className={offsetValue >= lastOffset ? styles.disable: ""}><i className={`ri-arrow-right-s-line`} onClick={handleRightClick}></i></div>
                    <div className={offsetValue >= lastOffset ? styles.disable: ""}><i className="ri-arrow-right-double-line" onClick={handleDoubleRightClick}></i></div>
                </span>
            </div>
        </div>
    );
};