import {
  IAssetsState,
  IActiveAssetTab,
  IExplorers,
  IPerDay,
  IAssetDetails,
  IAssetOverview,
  IAssestAllDeatils,
  IAssestAllLoaderDeatils,
  IOrderState,
} from "./type";
import { IActiveTabData } from "views/exchange/components/type";
import { atom } from "recoil";
import {
  marketOverviewData,
  mediaCoverageData,
  overviewData,
  partnershipData,
  prominentData,
  teamData,
  technicalChartData,
  theProblemData,
  theSolutionData,
  toInvestData,
  tractionData,
  whatWeDoData,
} from "./constants";
// @ts-ignore
import { Json } from "@types/common";

export const ExplorerState = atom<IExplorers>({
  key: "explorer-state",
  default: {
    IWatchlist1: [],
    stocks: [],
    crypto: [],
    privates: [],
    commodities: [],
    forex: [],
    marketIndexes: [],
  },
});

export const AddedToWatchListState = atom<boolean>({
  key: "added-to-watchlist-state",
  default: false,
});

export const WatchlistState = atom<IActiveTabData[]>({
  key: "watchlist-state",
  default: [],
});

export const MusicState = atom<IActiveTabData[]>({
  key: "music-state",
  default: [],
});

export const SbaState = atom<any[]>({
  key: "sba-state",
  default: [],
});

export const StockState = atom<IActiveTabData[]>({
  key: "stock-state",
  default: [],
});

export const CryptoState = atom<IActiveTabData[]>({
  key: "crypto-state",
  default: [],
});
export const ForexState = atom<IActiveTabData[]>({
  key: "forex-state",
  default: [],
});
export const MarketIndexesState = atom<IActiveTabData[]>({
  key: "market-state",
  default: [],
});
export const PrivatesState = atom<IActiveTabData[]>({
  key: "private-state",
  default: [],
});
export const CommoditiesState = atom<IActiveTabData[]>({
  key: "commodity-state",
  default: [],
});
export const orderTableOpenState = atom<IOrderState[]>({
  key: "order-table-open-state",
  default: [],
});
export const orderTableExecuteState = atom<IOrderState[]>({
  key: "order-table-execute-state",
  default: [],
});
export const ActiveAssetTab = atom<IActiveAssetTab>({
  key: "active-asset-tab",
  default: {
    key: "",
    index: 0,
  },
});

export const ActiveCurrencyState = atom<IAssetsState>({
  key: "active-currency-state",
  default: {
    id: "",
    name: "",
    symbol: "",
    marketPrice: 0,
    change: 0,
    changesPercentage: 0,
    image: "",
    imageFrom: "",
    imageTo: "",
    perDay: [],
    type: "",
    address: "",
    issuerDetails: {}
  },
});

export const activeTypeState = atom<string>({
  key: "active-type",
  default: "stock",
});

export const perDayState = atom<IPerDay[]>({
  key: "per-day",
  default: [],
});

export const AssetDetailsState = atom<IAssetDetails>({
  key: "asset-details-state",
  default: {
    symbol: "",
    name: "",
    price: 0,
    changesPercentage: 0,
    change: 0,
    dayLow: 0,
    dayHigh: 0,
    yearHigh: 0,
    yearLow: 0,
    marketCap: 0,
    priceAvg50: 0,
    priceAvg200: 0,
    volume: 0,
    avgVolume: 0,
    exchange: "",
    open: 0,
    previousClose: 0,
    eps: 0,
    pe: 0,
    sharesOutstanding: 0,
    timestamp: 0,
  },
});

export const AssetAllDetailsState = atom<IAssestAllDeatils>({
  key: "all-asset-details-state",
  default: {
    balanceSheet: {
      netDebt: 0,
      totalAssets: 0,
      totalDebt: 0,
    },
    profile: {
      address: "",
      beta: 0,
      ceo: "",
      changes: 0,
      cik: "",
      city: "",
      companyName: "",
      country: "",
      currency: "",
      cusip: "",
      dcf: 0,
      dcfDiff: 0,
      defaultImage: true,
      description: "",
      exchange: "",
      exchangeShortName: "",
      fullTimeEmployees: "",
      image: "",
      industry: "",
      ipoDate: "",
      isActivelyTrading: true,
      isAdr: true,
      isEtf: true,
      isFund: true,
      isin: "",
      lastDiv: 0,
      mktCap: 0,
      phone: "",
      price: 0,
      range: "",
      sector: "",
      state: "",
      symbol: "",
      volAvg: 0,
      website: "",
      zip: "",
    },
    detail: {
      avgVolume: 0,
      change: 0,
      changesPercentage: 0,
      dayHigh: 0,
      dayLow: 0,
      earningsAnnouncement: "",
      eps: 0,
      exchange: "",
      marketCap: 0,
      name: 0,
      open: 0,
      pe: 0,
      previousClose: 0,
      price: 0,
      priceAvg50: 0,
      priceAvg200: 0,
      sharesOutstanding: 0,
      symbol: "",
      timestamp: 0,
      volume: 0,
      yearHigh: 0,
      yearLow: 0,
    },
    currentPrice: {
      price: 0,
      symbol: "",
      volume: 0,
    },
    priceToBook: 0,
    dividendYield: 0,
    enterpriseValue: 0,
    priceToEarning: 0,
    news: [],
  },
});

export const AssetLoaderState = atom<IAssestAllLoaderDeatils>({
  key: "loader-asset-details-state",
  default: {
    IsLoading: false,
  },
});

export const NewsStateData = atom<any>({
  key: "news-state-data",
  default: {
    overview: overviewData,
    the_problem: theProblemData,
    the_solution: theSolutionData,
    what_we_do: whatWeDoData,
    reasons_to_invest: toInvestData,
    prominent_investor: prominentData,
    partnership: partnershipData,
    team: teamData,
    media_coverage: mediaCoverageData,
    market_overview: marketOverviewData,
    technical_chart: technicalChartData,
    traction_data: tractionData,
  },
});

export const IsOrderModifyingState = atom<boolean>({
  key: "is-order-modifying-state",
  default: false,
});

export const IsOpenAssetLoadingState = atom<boolean>({
  key: "is-open-asset-loading-state",
  default: false,
});

export const AssetOverview = atom<IAssetOverview | Record<string, never>>({
  key: "complete-data-state",
  default: {},
});

export const initialQueryPrivateState = atom({
  key: "query-private-state",
  default: { limit: 20, offset: 0, include: "privates" },
});

export const initialQuerySbaState = atom({
  key: "query-sba-state",
  default: { limit: 20, offset: 0, include: "sba7" },
});

export const SocketTriggeredOnAssetIdState = atom<Json>({
  key: "socket-triggered-on-assetId-state",
  default: {},
});

export const SocketTriggeredOnOrderState = atom<Json>({
  key: "socket-triggered-on-order-state",
  default: {},
});
